.code {
  padding: mixins.toRem(5) mixins.toRem(20) mixins.toRem(5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: mixins.toRem(17);
  border: 1px dashed var(--gray2);
  border-radius: 8px;
  height: mixins.toRem(36);


  @media (max-width: mixins.toEem(380)) {
    padding: mixins.toRem(5) mixins.toRem(10) mixins.toRem(5);
    gap: mixins.toRem(10);
  }


  // .code__value

  &__value {
    font-weight: 700;
    color: var(--dark-blue);



    &--balance {
      font-size: mixins.toRem(14);
      color: #161616;
      font-weight: 400;
    }

    @media (max-width: mixins.toEem(350)) {
      font-size: mixins.toRem(13);
    }
  }

}
