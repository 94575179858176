.chat__input-icon {
}

.chat {
  // .chat__title

  &__title {
    margin: 0 auto;
    margin-bottom: mixins.toRem(26);
  }

  // .chat__date

  &__date {
    margin-bottom: mixins.toRem(24);
    font-family: var(--third-family);
    font-weight: 400;
    font-size: mixins.toRem(12);
    color: #666668;
    text-align: center;
  }

  // .chat__items

  &__items {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 50vh;
    height: 100%;
  }

  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #8e8e93;
    text-align: center;
  }

  &__messages {
    margin-bottom: mixins.toRem(25);
  }

  // .chat__message

  &__message {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(13);
    }

    padding: mixins.toRem(8) mixins.toRem(15) mixins.toRem(0);
    font-size: mixins.toRem(14);
    line-height: 143%;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: mixins.toRem(10);
      height: mixins.toRem(12);
      top: 0;
    }

    &--admin {
      background-color: #f0f8ff; // светло-голубой фон для сообщений админа
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      max-width: 80%;
    }
  }

  // .chat__message--user
  &__message--user {
    border-radius: 6px 0 6px 6px;
    margin-left: 10%;
    margin-right: mixins.toRem(8);
    background-color: var(--gray1);
    color: #2c2c2e;

    &::after {
      right: mixins.toRem(-8);
      background: url("../../../public/img/svg/tip.svg") 0 0 no-repeat;
    }
  }

  &__message-sender {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 10px;
    color: #666;
    font-weight: 500;
  }

  // .chat__message--support
  &__message--support {
    border-radius: 0 6px 6px 6px;
    margin-right: 10%;
    margin-left: mixins.toRem(8);
    background-color: var(--blue);
    color: var(--white);

    &::after {
      left: mixins.toRem(-8);
      background: url("../../../public/img/svg/tip-blue.svg") 0 0 no-repeat;
    }
  }

  // .chat__message-text

  &__message-text {
  }

  // .chat__message-time

  &__message-time {
    margin-right: mixins.toRem(-5);
    display: flex;
    justify-content: end;
    align-items: center;
    gap: mixins.toRem(3);
    font-family: var(--second-family);
    font-weight: 400;
    font-size: mixins.toRem(12);

    &--user {
      color: #666668;
    }
  }

  &__message-check {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: "";
      position: absolute;
      right: mixins.toRem(-6);
      top: 0;
      width: 100%;
      height: 100%;
      background: url("../../../public/img/svg/check-mes2.svg") 0 0 no-repeat;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;
    }

    &.read {
      &::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__message-check {
  }

  // .chat__input-container

  &__input-container {
    padding: mixins.toRem(16) mixins.toRem(12);
    display: flex;
    align-items: start;
    gap: mixins.toRem(16);
    border-top: 1px solid #e5e5ea;
    position: relative;
  }

  .emoji-picker-container {
    position: relative;
  }

  // .chat__input-btn

  &__input-btn {
    &--emoji {
      cursor: pointer;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  // .chat__input-icon

  &__input-icon {
    width: mixins.toRem(16);
    height: mixins.toRem(16);
  }

  // .chat__input-icon--emoji

  &__input-icon--emoji {
  }

  // .chat__input

  &__input {
    margin-top: mixins.toRem(-2);
    flex-grow: 1;
    resize: none;
    overflow-y: hidden;
    font-size: mixins.toRem(14);
    line-height: 143%;
    color: #2c2c2e;
    outline: none;

    &::placeholder {
      font-family: var(--second-family);
      color: #666668;
    }
  }

  // .chat__input-icon--mention

  &__input-icon--mention {
  }

  // .chat__input-icon--send

  &__input-icon--send {
    fill: #8e8e93;
    transition: fill 0.3s ease;

    &.send {
      fill: #2c2c2e;
    }
  }

  // .chat__actions

  &__actions {
    display: flex;
    gap: mixins.toRem(10);
  }

  // .chat__button

  &__button {
    &--reject {
      font-size: mixins.toRem(12);
    }
  }

  &__no-messages {
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    font-size: 14px;
  }
}
