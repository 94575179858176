.referrer {

  // .referrer__title

  &__title {}

  // .referrer__logo

  &__logo {

    padding-top: mixins.toRem(20);
    padding-bottom: mixins.toRem(20);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: mixins.toRem(14);
  }

  // .referrer__logo-icon

  &__logo-icon {

    width: mixins.toRem(185);
  }

  // .referrer__logo-text

  &__logo-text {
    text-align: center;
  }

  // .referrer__logo-name

  &__logo-name {
    font-family: var(--logo);
    color: #002661;
    font-size: mixins.toRem(39);
    line-height: 0.9;
  }

  // .referrer__logo-descr

  &__logo-descr {
    font-family: var(--third-family);
    font-size: mixins.toRem(19);
    color: #00b6f1;
  }

  // .referrer__address

  &__address {
    margin-bottom: mixins.toRem(18);
  }
}
