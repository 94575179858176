.client-main-load {

  // .client-main-load__title

  &__title {}

  // .client-main-load__body

  &__body {
    padding-bottom: mixins.toRem(60);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
  }

  // .client-main-load__logo

  &__logo {
    margin-bottom: mixins.toRem(100);
    width: mixins.toRem(219);
  }

  // .client-main-load__progress

  &__progress {}
}


.progress-load {
  padding: 0 mixins.toRem(35);
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  width: 100%;
  align-items: center;
  gap: mixins.toRem(7);

  @media (max-width: mixins.toEem(380)) {
    padding: 0 mixins.toRem(30);
  }

  // .progress-load__item

  &__item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: mixins.toRem(7);
  }



  // .progress-load__item-circle

  &__item-circle {
    position: relative;
  }

  &__item-icon {
    width: mixins.toRem(30);
    height: mixins.toRem(30);
    border-radius: 50%;
    background-color: var(--white);
    border: 1px solid var(--gray4);
    transition: border 0.3s ease, background 0.3s ease;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      visibility: hidden;
    }

    .progress-completed & {
      background-color: var(--dark-blue);
      border: 1px solid var(--dark-blue);

      &::before {
        opacity: 1;
        visibility: visible;
        background: url('../../../public/img/svg/check-load.svg') center / mixins.toRem(12) mixins.toRem(10) no-repeat;
      }
    }

    .progress-active & {

      border: 2px solid var(--dark-blue);

      &::before {
        opacity: 1;
        visibility: visible;
        width: mixins.toRem(12);
        height: mixins.toRem(12);
        background-color: var(--dark-blue);
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }


  &__item-line {
    position: relative;
    width: calc(100% - mixins.toRem(37));
    background-color: #c5c5c5;
    height: mixins.toRem(4);
    border-radius: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background-color: var(--dark-blue);
      border-radius: 10px;
    }

    .progress-completed & {
      &::before {
        width: 100%;
      }
    }

    .progress-active & {
      &::before {
        width: 50%;
      }
    }
  }

  // .progress-load__item-text

  &__item-text {
    position: absolute;
    transform: translate(-50%, 0px);
    top: calc(100% + mixins.toRem(6));
    left: 50%;
    font-size: mixins.toRem(14);
    line-height: 110%;
    text-align: center;
    color: #acacac;

    @media (max-width: mixins.toEem(380)) {
      font-size: mixins.toRem(12);
    }

    .progress-completed &,
    .progress-active &{
      color: #161616;
      font-weight: 500;
    }
  }
}

.progress-completed {}

.progress-active {}



.progress-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #ccc;
}

.step .circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 8px;
  color: white;
}

.step.completed .circle {
  background-color: #007bff;
}

.step.active .circle {
  background-color: #007bff;
  color: #007bff;
  border: 2px solid #007bff;
  font-size: 20px;
}

.step.completed span,
.step.active span {
  color: #007bff;
}

.line {
  flex: 1;
  height: 2px;
  background-color: #ccc;
  position: relative;
  margin: 0 10px;
}

.line.completed::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #007bff;
  z-index: -1;
  animation: load 2s forwards;
}

@keyframes load {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.step:not(.completed) .circle::after {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}
