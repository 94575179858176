
.addresses {

  &__title {
    margin-bottom: mixins.toRem(20);
  }


  &__table {}
}


