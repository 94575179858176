@use "./mixins/font-face" as ff;

@include ff.font-face("Poppins", "../../../public/fonts/Poppins-Regular", 400, normal);
@include ff.font-face("Poppins", "../../../public/fonts/Poppins-SemiBold", 600, normal);

@include ff.font-face("BalooTamma", "../../../public/fonts/BalooTamma-Regular", 400, normal);

@include ff.font-face("HelveticaNeueCyr", "../../../public/fonts/helveticaneuecyr-roman", 400, normal);
@include ff.font-face("HelveticaNeueCyr", "../../../public/fonts/helveticaneuecyr-medium", 500, normal);
@include ff.font-face("HelveticaNeueCyr", "../../../public/fonts/helveticaneuecyr-bold", 700, normal);
@include ff.font-face("HelveticaNeueCyr", "../../../public/fonts/helveticaneuecyr-light", 300, normal);

@include ff.font-face("Eloquia", "../../../public/fonts/Eloquia-ExtraLight", 300, normal);
// @include font-face("Eloquia", "../fonts/Eloquia-ExtraBold", 400, normal);
