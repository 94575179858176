
.transactions-log {

  // .transactions-log__title

  &__title {
    margin-bottom: mixins.toRem(20);
  }

  // .transactions-log__table

  &__table {}
}


