.settings {
  // .settings__form

  &__form {}

  // .settings__form-items

  &__form-items {
    margin-bottom: mixins.toRem(24);
  }

  // .settings__code

  &__code {
    display: flex;
    align-items: center;
    gap: mixins.toRem(15);
  }

  // .settings__code-qr

  &__code-qr {
    width: mixins.toRem(114);
    height: mixins.toRem(114);

    @media (max-width: mixins.toEem(350)) {
      width: mixins.toRem(80);
      height: mixins.toRem(80);
    }
  }

  // .settings__code-content

  &__code-content {
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(13);
    align-items: start;
  }

  // .settings__code-label

  &__code-label {

    font-size: mixins.toRem(14);
    line-height: 110%;
  }

  // .settings__code-link

  &__code-link {}

  // .settings__code-value

  &__code-value {}

  // .settings__code-copy

  &__code-copy {}

  // .settings__code-icon

  &__code-icon {}
}
