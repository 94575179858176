.navigation {
  margin-bottom: mixins.toRem(24);

  // .navigation__list

  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: mixins.toRem(10);
  }

  // .navigation__item

  &__item {}

  // .navigation__link

  &__link {
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(7);
    align-items: center;

    @media (max-width: mixins.toEem(350)) {
      gap: mixins.toRem(5);
    }

    // transition: opacity 0.3s ease;

    &:active {
      .navigation__link-icon {
        fill: var(--state-blue);
      }

      .navigation__link-text {
        color: var(--stater-blue);
      }
    }

    @media (any-hover: hover) {
      &:hover {
        .navigation__link-icon {
          fill: var(--state-blue);
        }

        .navigation__link-text {
          color: var(--state-blue);
        }
      }
    }
  }

  // .navigation__link-icon

  &__link-icon {
    width: mixins.toRem(24);
    height: mixins.toRem(24);
    fill: var(--dark-blue);
    transition: fill 0.3s ease 0s;
  }

  // .navigation__link-text

  &__link-text {
    font-weight: 500;
    font-size: mixins.toRem(15);
    line-height: 100%;
    text-align: center;
    color: var(--blue);
    transition: color 0.3s ease 0s;

    @media (max-width: mixins.toEem(350)) {
      font-size: mixins.toRem(14);
    }
    @media (max-width: mixins.toEem(340)){
        font-size: mixins.toRem(13);
    }

  }
}
