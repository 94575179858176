.help {
  padding-top: mixins.toRem(8);
  position: absolute;
  top: mixins.toRem(-17);
  right: mixins.toRem(-15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: mixins.toRem(60);
  height: mixins.toRem(60);
  background-color: var(--blue);
  border-radius: 50%;
  color: var(--white);
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);

  // .help__icon

  &__icon {
    width: mixins.toRem(24);
    height: mixins.toRem(24);
  }

  // .help__text

  &__text {
    font-size: mixins.toRem(11);
  }
}
