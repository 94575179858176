// базовые подключения
@use "sass:math";
@use "mixins";

@import "vars";
@import "fonts";
@import "settings";

@import "./components/button";
@import "./components/common";
@import "./components/navigation";
@import "./components/logo";
@import "./components/header";
@import "./components/help";
@import "./components/options";
@import "./components/form";
@import "./components/code";
@import "./components/done";
@import "./components/rating";
@import "./components/table-transactions";
@import "./components/table-addresses";
@import "./components/addresses";

@import "./components/client-main";
@import "./components/client-main-load";
@import "./components/client-transaction-detail";
@import "./components/client-order-control";
@import "./components/entering-details";
@import "./components/settings";
@import "./components/entering-details_cash";
@import "./components/transaction-type";
@import "./components/deal-done";
@import "./components/confirms-transfer";
@import "./components/deal-done-cash";
@import "./components/transactions";
@import "./components/transactions-log";
@import "./components/trader-main";
@import "./components/referrals";
@import "./components/top-up-balance";
@import "./components/referrer";
@import "./components/history-referrals";
@import "./components/notification";
@import "./components/cards";
@import "./components/details-type";
@import "./components/check";
@import "./components/chat";
@import "./components/transaction";
@import "./components/modal";

body {
  .lock & {
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }
}

/* Стили для индикатора загрузки */
.loading-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  text-align: center;
}

.loading-indicator {
  margin-bottom: 20px;
}

.emoji-picker {
  position: absolute;
  bottom: 120px;
  left: 24px;
  z-index: 1000;
}

.emoji-picker-react .emoji-group:before {
  font-size: 12px !important; /* Размер текста */
  padding: 5px 0 !important; /* Отступы */
}

/* Уменьшаем размер эмодзи */
.emoji-picker-react .emoji {
  width: 20px !important; /* Ширина эмодзи */
  height: 20px !important; /* Высота эмодзи */
}

/* Уменьшаем размер иконок категорий */
.emoji-picker-react .emoji-categories button {
  width: 20px !important; /* Ширина иконки */
  height: 20px !important; /* Высота иконки */
}

/* Уменьшаем высоту области с эмодзи */
.emoji-picker-react .emoji-scroll-wrapper {
  height: 250px !important; /* Высота области */
}

/* Уменьшаем размер текста в поисковой строке */
.emoji-picker-react .emoji-search {
  font-size: 12px !important; /* Размер текста */
  padding: 5px !important; /* Отступы */
}

/* Уменьшаем размер иконки поиска */
.emoji-picker-react .emoji-search-icon {
  width: 16px !important; /* Ширина иконки */
  height: 16px !important; /* Высота иконки */
}

.spinner {
  border: 4px solid #f3f3f3; /* Цвет фона */
  border-top: 4px solid #3498db; /* Цвет спиннера */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

/* Анимация для спиннера */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-text ul {
  list-style-type: none;
  padding: 0;
}

.loading-text li {
  font-size: 16px;
  color: #555;
}

.loading-main-text {
  margin-bottom: 14px;
}

.wrapper {
  background-color: var(--bg);
  padding: mixins.toRem(21) mixins.toRem(16);
  min-height: 100vh;
  border-radius: 16px 16px 0 0;
  // max-width: 390px;
  margin: 0 auto;
  display: grid;
  overflow: hidden;
  overflow-y: auto;
}

.page {
  max-width: mixins.toRem(500);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main {
  position: relative;
  padding: mixins.toRem(20) mixins.toRem(16);
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 12px;

  @media (max-width: mixins.toEem(340)) {
    padding: mixins.toRem(20) mixins.toRem(10);
  }
}

.body {
  &:not(:last-child) {
    margin-bottom: mixins.toRem(20);
  }

  flex: 1 1 auto;
  position: relative;
}

.error {
  color: red;
  font-size: 10px;
  font-weight: 400;
  margin-top: 10px;
}
