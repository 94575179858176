.table-addresses {
  margin: 0 mixins.toRem(-16);

   &__title {
    margin: 0 mixins.toRem(16);
    margin-bottom: mixins.toRem(7);
    font-weight: 700;
    font-size: mixins.toRem(14);
    line-height: 100%;
    color: var(--black);
  }

  &__items {
    padding: 0 mixins.toRem(16);
    display: grid;
    grid-template-columns: minmax(mixins.toRem(48), mixins.toRem(60)) minmax(mixins.toRem(48), mixins.toRem(70)) 1fr minmax(mixins.toRem(40), mixins.toRem(44));
    gap: mixins.toRem(10);
    justify-content: space-between;
    align-items: center;
    min-height: mixins.toRem(57);
    font-size: mixins.toRem(12);
    line-height: 110%;
    color: #2c2c2e;
    border-bottom: 1px solid #e1e0e6;

    @media (max-width: mixins.toEem(380)) {
      font-size: mixins.toRem(12);
      gap: mixins.toRem(5);
      min-height: mixins.toRem(50);
    }


  }



  &__items--head {
    font-size: mixins.toRem(11);
    min-height: mixins.toRem(20);
  }


  &__item {
    line-height: 110%;
    color: #2c2c2e;
    overflow: hidden;
    text-overflow: ellipsis;
  }



  &__item--id {
    color: var(--blue);

  }
}
