.main {}

.history-referrals {

  // .history-referrals__title

  &__title {
    margin-bottom: mixins.toRem(20);
  }
}



