.top-up-balance {

  // .top-up-balance__code

  &__code {
    padding: mixins.toRem(24) 0;
    height: 100%;
    display: grid;
    align-items: center;
    align-content: center;
    justify-items: center;
  }

  // .top-up-balance__code-qr

  &__code-qr {
    margin-bottom: mixins.toRem(30);
    width: mixins.toRem(200);
    height: mixins.toRem(200);
  }


  // .top-up-balance__code-link

  &__code-link {
    width: 100%;
  }
}
