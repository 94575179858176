
.details-type {
  position: relative;
  margin-top: auto;
  padding: mixins.toRem(30) mixins.toRem(16) mixins.toRem(20);

  display: flex;
  flex-direction: column;

  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  border-radius: 12px;

  @media (max-width: mixins.toEem(340)) {
    padding: mixins.toRem(20) mixins.toRem(10);
  }

  // .details-type__title

  &__title {
  margin-bottom: mixins.toRem(25);
  }
}

