.form {
  display: flex;
  flex-direction: column;
  // .form__items

  &__items {
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(10);
  }

  // .form__item

  &__item {
    display: flex;
    flex-direction: column;
  }

  &__item-payment-method {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  // .form__item-row

  &__item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  // .form__label

  &__label {
    margin-bottom: mixins.toRem(7);
    display: block;
    font-size: mixins.toRem(11);
  }

  // .form__control

  &__control {
  }

  // .form__item-bottom

  &__item-bottom {
    margin-top: mixins.toRem(4);
    color: var(--light-blue);
    font-size: mixins.toRem(11);
  }

  // .form__converter

  &__converter {
    margin-bottom: mixins.toRem(7);
    font-size: mixins.toRem(11);
    color: #b9b9b9;
  }
}

.form-control {
  padding: mixins.toRem(7) mixins.toRem(14);
  display: flex;
  gap: mixins.toRem(12);
  align-items: center;

  outline: 1px solid #e1e0e6;
  background-color: var(--white);
  border-radius: 8px;
  width: 100%;
  height: mixins.toRem(50);
  font-weight: 500;
  font-size: mixins.toRem(14);
  transition: outline 0.3s ease;

  overflow: hidden;

  &--transfer {
    height: mixins.toRem(46);
  }

  &--referrer {
    height: mixins.toRem(46);
    font-weight: 500;
  }

  &__img {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: mixins.toRem(32);

    &--currency,
    &--btn {
      height: mixins.toRem(32);
      border-radius: 50%;
      background-color: var(--gray2);
    }

    &--city {
      height: mixins.toRem(32);
      border-radius: 6px;
      background-color: var(--gray1);
    }
  }

  // .form-control__icon
  &__icon {
    color: black;
  }

  // .form-control__input
  &__input {
    outline: none;
    height: 100%;
    font-weight: 500;
  }

  // .form-control__text
  &__text {
    flex: 1 1 auto;
    text-align: start;
    font-weight: 500;
    font-size: mixins.toRem(14);
    line-height: 140%;

    text-overflow: ellipsis;
  }

  &__text-dropdown {
    flex: 1 1 auto;
    text-align: start;
    font-weight: 500;
    font-size: mixins.toRem(14);
    line-height: 140%;

    text-overflow: ellipsis;
    margin-left: 10px;
  }

  // .form-control__text-currency

  &__text-currency {
    font-weight: 400;
    color: var(--gray3);
  }

  // .form-control__arrow

  &__arrow {
  }

  &:focus {
    outline: 1px solid var(--orange);
  }
}

.form-control:has(.form-control__input:focus) {
  outline: 1px solid var(--orange);
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 70px;
  list-style-type: none;
  padding: 0;
  width: 100%;
  z-index: 1000;
}

.dropdown__payment-method-menu {
  position: absolute;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 55px;
  list-style-type: none;
  padding: 0;
  width: 100%;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

.form-control-method__img {
  margin-right: 10px;
}

.dropdown__menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  margin-top: 10px;
  border-radius: 10px;
  width: 91%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}

.dropdown__item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdown__item:hover {
  background-color: #f1f1f1;
}

.dropdown__img {
  width: 20px;
  margin-right: 10px;
}

.form-control__arrow.open {
  transform: rotate(180deg);
}
