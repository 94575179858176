* {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*::before,
*::after {
  display: inline-block;
}


img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

ul li {
  list-style: none;
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

input,
button,
textarea {
  font-family: var(--font-family, sans-serif);
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: transparent;
}

input,
textarea {
  width: 100%;
}

button,
select,
option {
  cursor: pointer;
}


html,
body {
  height: 100%;
  min-width: mixins.toRem(320);
}

body {
  color: var(--main-color);
  line-height: 1;
  font-family: var(--font-family, sans-serif);
  font-size: mixins.toRem(16);
  //text-rendering: optimizeLegibility;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;


  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}



input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;

}

input[type="number"] {

  -moz-appearance: textfield;

}
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}

.grayfilter {

  filter: grayscale(1);
  opacity: 0.7;
  pointer-events: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}



.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}




.disable-scroll {
  position: relative;
  overflow: hidden;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
