.main {}

.cards {

  // .cards__title

  &__title {}

  // .cards__items

  &__items {
    margin-bottom: mixins.toRem(20);
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(16);
  }

  // .cards__item

  &__item {}

  // .cards__label

  &__label {
    display: block;
    margin-bottom: mixins.toRem(6);
    font-size: mixins.toRem(11);
  }

  // .cards__body

  &__body {
    padding: mixins.toRem(14);
    border-radius: 8px;
    background-color: var(--gray1);
    display: grid;
    grid-template-columns: mixins.toRem(32) 1fr mixins.toRem(20);
    align-items: center;
    gap: mixins.toRem(12);
  }

  // .cards__icon

  &__icon {
    width: 100%;
    object-fit: contain;
  }

  // .cards__info

  &__info {

    display: flex;
    flex-direction: column;
    gap: mixins.toRem(6);
    font-weight: 500;
  }

  // .cards__code

  &__code {
    font-size: mixins.toRem(14);
  }

  // .cards__term

  &__term {
    font-size: mixins.toRem(12);
  }

  // .cards__btn-del

  &__btn-del {}

  // .cards__btn-del-icon

  &__btn-del-icon {
    width: mixins.toRem(20);
    height: mixins.toRem(20);
    fill: var(--red);
  }

  // .cards__add-btn

  &__add-btn {
    display: flex;
    align-items: center;
    gap: mixins.toRem(7);
  }

  &__add-btn-icon {
    width: mixins.toRem(14);
    height: mixins.toRem(14);
  }
}
