.logo {
  display: flex;
  align-items: center;
  gap: mixins.toRem(7);
  // .logo__icon

  &__icon {
    width: mixins.toRem(47);
  }

  // .logo__text

  &__text {}

  // .logo__name

  &__name {
    font-family: var(--logo);
    // line-height: 120%;
    color: #002661;
  }

  // .logo__descr

  &__descr {
    font-family: var(--third-family);
    font-weight: 600;
    font-size: mixins.toRem(10);
    color: #00b6f1;
  }
}
