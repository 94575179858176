.button {
  padding: mixins.toRem(5);
  position: relative;
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: mixins.toRem(46);
  border-radius: 6px;
  background: var(--orange);
  color: var(--white);
  font-weight: 500;
  transition: background 0.3s ease 0s;



  &:active {
    background-color: var(--state-orange);
  }

  @media (any-hover: hover) {
    &:hover {
      background-color: var(--state-orange);
    }
  }

  &:disabled {
    background-color: rgb(205, 205, 205);
  }

  &--blue-border {
    background: var(--white);
    border: 1px solid var(--dark-blue);
    color: var(--dark-blue);
    font-size: mixins.toRem(14);
    transition: border 0.3s ease;

    &:active {
      background-color: var(--white);
      border: 1px solid var(--state-blue);
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: var(--white);
        border: 1px solid var(--state-blue);
      }
    }
  }

  &--gray-border {
    background: var(--white);
    border: 1px solid var(--gray2);
    color: #010101;
    font-weight: 400;
    font-size: mixins.toRem(14);
    transition: border 0.3s ease;

    &:active {
      background-color: var(--white);
      border: 1px solid var(--gray5);
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: var(--white);
        border: 1px solid var(--gray5);
      }
    }
  }

  &--orange-border {
    background: var(--white);
    border: 1px solid var(--orange);
    color: #010101;
    font-weight: 400;
    font-size: mixins.toRem(14);
    transition: border 0.3s ease;

    &:active {
      background-color: var(--white);
      border: 1px solid var(--state-orange);
    }

    @media (any-hover: hover) {
      &:hover {
        background-color: var(--white);
        border: 1px solid var(--state-orange);
      }
    }
  }

  &--gray {
    background: var(--gray2);
    color: var(--black);

    &:active {
      background: var(--gray5);

    }

    @media (any-hover: hover) {
      &:hover {
        background: var(--gray5);
      }
    }
  }

  &.disable {
    background: var(--gray4);
    pointer-events: none;

  }







}
