.main {}

.client-order-control {

  // .client-order-control__info

  &__info {
    margin-bottom: mixins.toRem(14);
  }

  // .client-order-control__time

  &__time {
    margin-bottom: mixins.toRem(14);
  }

  // .client-order-control__status

  &__status {
    margin-bottom: mixins.toRem(14);
  }

  // .client-order-control__summary

  &__summary {}

  // .client-order-control__footer

  &__footer {
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(10);
  }

  // .client-order-control__actions

  &__actions {
    display: flex;
    align-items: center;
    gap: mixins.toRem(10);
    font-size: mixins.toRem(12);

  }

  // .client-order-control__btn

  &__btn {
    font-size: mixins.toRem(12);
    text-align: center;
    height: mixins.toRem(40);
  }
}

.order-control-info {

  // .order-control-info__title

  &__title {
    margin-bottom: mixins.toRem(32);
  }

  // .order-control-info__text

  &__text {
    line-height: 120%;
    text-align: center;
    color: #010101;
  }

  // .order-control-info__text--order

  &__text--order {
    font-weight: 700;
    color: var(--blue);
  }
}


.order-control-status {

  // .order-control-status__label

  &__label {
    display: block;
    margin-bottom: mixins.toRem(5);
    font-size: mixins.toRem(14);
    color: var(--gray5);
  }

  // .order-control-status__value

  &__value {
    padding: mixins.toRem(5);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: mixins.toRem(33);
    color: var(--white);
    background-color: var(--blue);
    border-radius: 4px;
    font-weight: 700;
    font-size: mixins.toRem(14);
  }
}
