.main {}

.check {

  // .check__title

  &__title {}

  // .check__body

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  // .check__logo

  &__logo {
    width: mixins.toRem(149);
    margin-bottom: mixins.toRem(84);
  }

  // .check__text

  &__text {
    margin-bottom: mixins.toRem(17);
    font-weight: 400;
    font-size: mixins.toRem(18);
    line-height: 120%;
    text-align: center;
  }

  // .check__actions

  &__actions {
    display: flex;
    align-items: center;
    gap: mixins.toRem(8);
  }

  // .check__link

  &__link {
    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: var(--blue);

    @media (any-hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }

  // .check__btn-pdf

  &__btn-pdf {}

  // .check__important

  &__important {
    margin-bottom: mixins.toRem(20);
    font-weight: 300;
    font-size: mixins.toRem(14);
    line-height: 120%;
    text-align: center;
    color: var(--gray5);
  }

  // .check__buttons

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: mixins.toRem(10);
  }
}
