.done {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: mixins.toRem(20);
  // .done__icon

  &__icon {
    width: mixins.toRem(70);
    height: mixins.toRem(70);
  }

  // .done__text

  &__text {
    font-weight: 700;
    font-size: mixins.toRem(18);
    line-height: 120%;
    text-align: center;
    color: #010101;
  }

  // .done__text-code

  &__text-code {
    color: var(--blue);
  }
}
