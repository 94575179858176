.main {}

.transactions {

  // .transactions__title

  &__title {
    // margin-bottom: mixins.toRem(25);
  }


  &__checkbox {
    margin-bottom: mixins.toRem(13);
  }

  // .transactions__items

  &__items {
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(10);
  }

  // .transactions__item

  &__item {}
}


.item-transactions {
  padding: mixins.toRem(9) mixins.toRem(9) mixins.toRem(9) mixins.toRem(15);
  display: grid;
  grid-template-columns: minmax(mixins.toRem(52), mixins.toRem(70)) minmax(mixins.toRem(90), mixins.toRem(125)) minmax(mixins.toRem(55), mixins.toRem(60));
  justify-content: space-between;

  align-items: center;
  gap: mixins.toRem(10);
  min-height: mixins.toRem(48);
  background-color: var(--gray1);
  border-radius: 6px;
  font-size: mixins.toRem(14);

  &--history {
    grid-template-columns: minmax(mixins.toRem(52), mixins.toRem(55)) minmax(mixins.toRem(95), mixins.toRem(109)) minmax(mixins.toRem(60), mixins.toRem(70)) mixins.toRem(30);

  }

  @media (max-width: mixins.toEem(380)) {
    padding: mixins.toRem(9);
    font-size: mixins.toRem(12);
    gap: mixins.toRem(7);
  }


  // .item-transactions__data

  &__data {
    line-height: 100%;
    color: #2c2c2e;
    overflow: hidden;
    text-overflow: ellipsis;

    &--order {
      font-weight: 700;
      color: var(--blue);
    }

    &--amount {
      font-weight: 700;
      color: #2c2c2e;
    }
  }


  // .item-transactions__message

  &__message {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: mixins.toRem(6);
    border-radius: 6px;

    height: mixins.toRem(30);
    background-color: #dcdceb;
    color: #010101;

    &.message-new {
      background-color: var(--orange);
      color: var(--white);

      .item-transactions__message-icon {
        background: url('../../../public/img/svg/mail.svg') 0 0 / mixins.toRem(16) no-repeat;

      }
    }
  }

  // .item-transactions__message-icon

  &__message-icon {
    width: mixins.toRem(16);
    height: mixins.toRem(16);
    justify-self: end;
    background: url('../../../public/img/svg/mail-2.svg') 0 0 / mixins.toRem(16) no-repeat;
  }

  &__status {
    border-radius: 6px;
    width: mixins.toRem(30);
    height: mixins.toRem(30);
    display: flex;
    align-items: center;
    justify-content: center;

    &--open {
      background-color: var(--orange);
    }

    &--done {
      background-color: var(--green);
    }

    &--reject {
      background-color: var(--red);
    }
  }


}




.checkbox {

  // .checkbox__input

  &__input {
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

}

.checkbox__input:checked+.checkbox__label::after {
  transform: scale(1);
}

.checkbox__input:checked+.checkbox__label::before {
  background-color: var(--dark-blue);
  outline: 1px solid var(--dark-blue);
}

.checkbox__input:focus-visible+.checkbox__label:before {
  outline: 1px solid var(--dark-blue);
}

.checkbox__label {

  font-size: mixins.toRem(13);
  display: flex;
  max-width: mixins.toRem(150);
  align-items: center;
  justify-items: start;
  position: relative;
  cursor: pointer;
}

.checkbox__label::before {
  content: "";
  align-self: flex-start;
  flex: 0 0 mixins.toRem(20);
  height: mixins.toRem(20);
  outline: 1px solid #e1e0e6;
  border-radius: 2px;
  margin: 0px mixins.toRem(7) 0px 0px;
}

.checkbox__label::after {
  content: "";
  width: mixins.toRem(16);
  height: mixins.toRem(16);
  position: absolute;
  top: 1px;
  left: 2px;
  background: url('../../../public/img/svg/check.svg') center no-repeat;
  border-radius: 6px;
  transform: scale(0);
}
