.title {
  font-weight: 700;
  font-size: mixins.toRem(18);
  line-height: 100%;
  text-align: center;
  color: var(--black);
}

.mb-title {
  margin-bottom: mixins.toRem(30);
}

.mb-24 {
  margin-bottom: mixins.toRem(24);
}


.copy-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    .copy-btn__icon {
      fill: var(--state-blue);
    }
  }

  @media (any-hover: hover) {
    &:hover {
      .copy-btn__icon {
        fill: var(--state-blue);
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: mixins.toRem(20);
    height: mixins.toRem(20);
    fill: var(--blue);
    transition: fill 0.3s ease;

    &--white {
      fill: var(--white);
    }
  }
}

.link-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  &:active {
    .link-btn__icon {
      stroke: var(--state-blue);
    }
  }

  @media (any-hover: hover) {
    &:hover {
      .link-btn__icon {
        stroke: var(--state-blue);
      }
    }
  }

  &__icon {
    flex-shrink: 0;
    width: mixins.toRem(20);
    height: mixins.toRem(20);
    stroke: var(--blue);
    stroke-width: 1.88px;
    fill: none;
    transition: fill 0.3s ease;

    &--orange {
      stroke: var(--orange);
    }
  }
}


.card {
  padding: mixins.toRem(22) mixins.toRem(20);
  border-radius: 6px;
  color: var(--white);
  background-color: var(--blue);

  &--gray {
    background-color: var(--gray1);
    color: #2c2c2e;
  }

  &--transparent {
    padding-top: 0;
    padding-bottom: 0;
    background-color: transparent;
    color: #2c2c2e;
  }
}
