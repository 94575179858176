

.transaction-type {

  // .transaction-type__form

  &__form {}

  &__form-items {
    gap: mixins.toRem(6);
  }

  // .transaction-type__options

  &__options {
    margin-bottom: mixins.toRem(6);
  }
}
