.referrals {

  // .referrals__title

  &__title {
    margin-bottom: mixins.toRem(15);
  }

  // .referrals__details

  &__details {
    margin-bottom: mixins.toRem(15);
    padding: mixins.toRem(20);
    min-height: mixins.toRem(129);
    background-color: var(--blue);
    border-radius: 12px;
    color: var(--white);
  }

  // .referrals__details-label

  &__details-label {
    margin-bottom: mixins.toRem(4);
    display: block;
    font-size: mixins.toRem(12);
  }

  // .referrals__details-amount

  &__details-amount {
    margin-bottom: mixins.toRem(18);
    font-weight: 500;
    font-size: mixins.toRem(36);
  }

  // .referrals__details-text

  &__details-text {
    font-size: mixins.toRem(12);
  }

  // .referrals__code

  &__code {
    margin-bottom: mixins.toRem(20);
    padding: mixins.toRem(13) mixins.toRem(20);
    border: 1px dashed var(--gray2);
    border-radius: 8px;
  }

  // .referrals__code-label

  &__code-label {
    margin-bottom: mixins.toRem(10);
    display: block;
    font-size: mixins.toRem(14);
    color: var(--gray5);
  }

  // .referrals__code-content

  &__code-content {
    display: flex;
    align-items: center;
    gap: mixins.toRem(19);

    @media (max-width: mixins.toEem(350)) {
      gap: mixins.toRem(15);
    }

  }

  // .referrals__code-actions

  &__code-actions {
    display: flex;
    align-items: center;
    gap: mixins.toRem(12);

    @media (max-width: mixins.toEem(350)) {
      gap: mixins.toRem(7);
    }
  }

  // .referrals__code-value

  &__code-value {
    font-weight: 500;
    font-size: mixins.toRem(20);
    line-height: 120%;
    color: var(--dark-blue);

    @media (max-width: mixins.toEem(350)) {
      font-size: mixins.toRem(18);
    }
  }

  &__table {}
}
