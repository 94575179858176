.notification {
  // .notification__title

  &__empty-list {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    font-size: 14px;
  }

  &__items {
    margin: 0 mixins.toRem(-16);
    display: flex;
    flex-direction: column;

    @media (max-width: mixins.toEem(340)) {
      margin: 0 mixins.toRem(-10);
    }
  }

  // .notification__item

  &__item {
    position: relative;
    padding: mixins.toRem(8) mixins.toRem(16);
    overflow: hidden;
    border-bottom: 1px solid var(--gray2);

    &:first-child {
      border-top: 1px solid var(--gray2);
    }

    &.notification-new {
      .notification__icon {
        fill: #b6b6ce;
      }

      .notification__img {
        background-color: #f2f2f7;
      }

      .notification__text {
        color: #2c2c2e;
      }

      .notification__order {
        color: #2c2c2e;
        color: var(--dark-blue);
      }

      &::after {
        content: "";
        position: absolute;
        width: mixins.toRem(8);
        height: mixins.toRem(8);
        background-color: var(--dark-blue);
        border-radius: 50%;
        right: mixins.toRem(16);
        top: 50%;
        transform: translate(0px, -50%);
      }
    }
  }

  // .notification__link

  &__link {
    display: grid;
    grid-template-columns: mixins.toRem(30) minmax(
        mixins.toRem(200),
        mixins.toRem(270)
      );
    gap: mixins.toRem(11);
  }

  // .notification__img

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: mixins.toRem(30);
    height: mixins.toRem(30);
    background-color: #f5f5f5;
    border-radius: 6px;
  }

  // .notification__icon

  &__icon {
    width: mixins.toRem(16);
    height: mixins.toRem(16);
    fill: #d7d7d7;
  }

  // .notification__body

  &__body {
    padding-right: mixins.toRem(10);
    font-size: mixins.toRem(14);
    line-height: 110%;
  }

  // .notification__date

  &__date {
    color: var(--gray5);
  }

  // .notification__descr

  &__descr {
    display: flex;
    gap: mixins.toRem(9);
  }

  // .notification__order

  &__order {
    font-weight: 500;
    color: var(--gray5);
  }

  // .notification__text

  &__text {
    color: var(--gray5);
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  // .notification__author

  &__author {
  }
}
