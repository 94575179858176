.header {
  margin-bottom: mixins.toRem(21);
  // .header__row

  &__row {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(13);
    }

    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: mixins.toRem(15);
    justify-items: center;
    // align-items: center;
  }

  &__notification-icon {
    position: relative;
    display: inline-block;
  }

  &__notification-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }

  // .header__back-btn

  &__back-btn {
    font-size: mixins.toRem(15);
    color: var(--blue);

    @media (any-hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  // .header__app-name

  &__app-name {
    font-weight: 700;
    font-size: mixins.toRem(15);
    color: var(--blak);
    margin-right: mixins.toRem(30);
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translate(0px, mixins.toRem(-8));
  }

  // .header__menu-btn

  &__menu-btn {
    width: mixins.toRem(24);
    height: mixins.toRem(24);
    border: 1px solid var(--blue);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border 0.3s ease 0s;

    @media (any-hover: hover) {
      &:hover {
        border: 1px solid var(--state-blue);

        .header__menu-icon {
          background-color: var(--state-blue);
        }

        .header__menu-icon::after,
        .header__menu-icon::before {
          background-color: var(--state-blue);
        }
      }
    }
  }

  // .header__menu-icon

  &__menu-icon {
    position: relative;
    width: mixins.toRem(2);
    height: mixins.toRem(2);
    border-radius: 50%;
    background-color: var(--blue);
    transition: background-color 0.3s ease 0s;

    &::after,
    &:before {
      content: "";
      position: absolute;
      width: mixins.toRem(2);
      height: mixins.toRem(2);
      border-radius: 50%;
      background-color: var(--blue);
      top: 50%;
      transform: translate(0px, -50%);
      transition: background-color 0.3s ease 0s;
    }

    &::after {
      left: mixins.toRem(-5);
    }

    &::before {
      right: mixins.toRem(-5);
    }
  }

  // .header__action

  &__action {
    width: mixins.toRem(36);
    height: mixins.toRem(36);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--gray2);
    overflow: hidden;

    @media (any-hover: hover) {
      &:hover {
        .header__action-icon {
          stroke: var(--state-blue);
        }
      }
    }
  }

  &__action-icon {
    width: mixins.toRem(16);
    height: mixins.toRem(16);
    fill: none;
    stroke: #000;
    transition: stroke 0.3s ease;
  }

  // .header__ava-img

  &__ava-img {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  // .header__logo

  &__logo {
    // margin-left: mixins.toRem(30);
    // width: 100%;
    // display: flex;
    // justify-content: center;
  }
}
