:root {

  --font-family: "HelveticaNeueCyr", sans-serif;
  --second-family: "Eloquia", sans-serif;
  --third-family: "Poppins", sans-serif;
  --logo: "BalooTamma", sans-serif;

  --bg: #f1f0f6;
  --gray1: #f2f2f7; // background

  --gray2: #e1e0e6; // icon, border select, border button
  --gray3: #a8a8a8; // select
  --gray4: #c5c5c5; // bottom dis 
  --gray5: #8b8a8a; // Client_transaction_detail
  --main-color: #161616;
  --black: #000;
  --white: #fff;

  --light-blue: #0479fb;
  --blue: #0076f7;
  --dark-blue: #007eff;
  --state-blue: #0c62b9;
  --orange: #f2af01;
  --state-orange: #dda107;
  --green: #3bb54a;
  --red: #eb001b;
}
