.client-main {

  // .client-main__body

  &__body {}

  // .client-main__title

  &__title {
    margin-bottom: mixins.toRem(14);
  }

  &__form {


  }

  &__form-items {
    margin-bottom: mixins.toRem(12);
  }

  &__form-item {}

  &__form-received {
    margin-bottom: mixins.toRem(15);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed var(--gray2);
    border-radius: 8px;
    width: 100%;
    height: mixins.toRem(46);
    font-weight: 500;
    font-size: mixins.toRem(24);
    color: var(--black);
  }

  &__form-options {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(11);
    }

  }

  &__form-card {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(11);
    }
  }

  &__form-requisites {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(11);
    }

    // margin-bottom: mixins.toRem(30);
    font-size: mixins.toRem(14);
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--blue);
    align-self: center;

    @media (any-hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }

  }

  // .client-main__footer

  &__footer {}

  // .client-main__button

  &__button {}
}
