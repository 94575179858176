.deal-done-cash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // .deal-done-cash__done

  &__done {
    margin-bottom: mixins.toRem(55);
  }

  // .deal-done-cash__details

  &__details {}
}





.done-details {

  // .done-details__item

  &__item {
    padding: mixins.toRem(16) mixins.toRem(16) mixins.toRem(16) 0;
    display: grid;
    gap: mixins.toRem(12);
    grid-template-columns: mixins.toRem(24) 1fr;

    &:not(:last-child) {
      border-bottom: 1px solid #e5e5ef;
    }
  }

  // .done-details__item-icon

  &__item-icon {
    padding-top: mixins.toRem(2);
    justify-self: center;
  }

  // .done-details__item-text

  &__item-text {}

  // .done-details__address-label

  &__address-label {
    font-weight: 700;
    font-size: mixins.toRem(15);
    line-height: 120%;
    color: #2c2c2e;
  }

  // .done-details__address

  &__address {
    font-style: normal;
    font-size: mixins.toRem(15);
    line-height: 120%;
    color: #2c2c2e;
  }

  // .done-details__time

  &__time {
    margin-bottom: mixins.toRem(6);
    font-weight: 700;
    line-height: 120%;
    color: #2c2c2e;
  }

  // .done-details__time-value

  &__time-value {
    color: #007bff;
  }

  // .done-details__time-descr

  &__time-descr {
    font-size: mixins.toRem(12);
    line-height: 130%;
    color: #2c2c2e;
  }
}
