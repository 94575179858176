.options {
  display: flex;
  flex-direction: column;
  gap: mixins.toRem(7);
  align-items: flex-start;


  &--address,
  &--type {
    gap: mixins.toRem(10);
    align-items: stretch;
  }

  // .options__item
  &__item {
    position: relative;
    cursor: pointer;
  }

  // .options__input
  &__input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus-visible+.options__label:before {
      outline-offset: 2px;
      outline: 1px solid var(--orange);
    }

    &:checked+.options__label:before {
      border: 1px solid var(--orange);
    }

    &:checked+.options__label:after {
      transform: scale(1);
    }
  }

  // .options__label
  &__label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: mixins.toRem(14);
    font-size: mixins.toRem(13);


    // line-height: 110%;

    &:before {
      content: "";
      align-self: flex-start;
      width: mixins.toRem(20);
      height: mixins.toRem(20);
      flex: 0 0 mixins.toRem(20);
      border-radius: 50%;
      background-color: var(--white);
      border: 1px solid var(--gray2);
    }

    &:after {
      content: "";
      position: absolute;
      left: mixins.toRem(7);
      top: mixins.toRem(7);
      width: mixins.toRem(6);
      height: mixins.toRem(6);
      border-radius: 50%;
      background: var(--orange);
      transition: transform 0.3s ease;
      transform: scale(0);
    }

    &--address {
      padding: mixins.toRem(14);
      width: 100%;
      height: mixins.toRem(48);
      background-color: var(--gray1);
      border-radius: 6px;

      &::after {
        top: mixins.toRem(21);
        left: mixins.toRem(21);
      }
    }

    &--type {
      padding: mixins.toRem(14) mixins.toRem(14) mixins.toRem(21);
      gap: mixins.toRem(12);
      width: 100%;
      background-color: var(--gray1);
      border-radius: 6px;

      &::after {
        top: mixins.toRem(21);
        left: mixins.toRem(21);
      }
    }

  }

  &__label-title {
    font-size: mixins.toRem(16);
    margin-top: mixins.toRem(3);
    margin-bottom: mixins.toRem(11);
    font-weight: 700;
    color: #2c2c2e;
  }

  &__label-text {
    font-size: mixins.toRem(12);
    color: #2c2c2e;
    line-height: 120%;
  }
}
