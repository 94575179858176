.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-left: 20px;
  margin-right: 20px;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.order-warning-modal {
  padding: 6px;
}

.order-warning-header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
}

.modal-actions {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 14px;
}

.order-warning-content {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
