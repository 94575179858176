.client-transaction-detail {
  // .client-transaction-detail__info

  &__info {
    margin: mixins.toRem(-20) mixins.toRem(-16) mixins.toRem(26);

    @media (max-width: mixins.toEem(340)) {
      margin: mixins.toRem(-20) mixins.toRem(-10) mixins.toRem(20);
    }
  }

  // .client-transaction-detail__summary

  &__summary {
    margin-bottom: mixins.toRem(14);
  }

  // .client-transaction-detail__time

  &__time {
  }
}

.transaction-detail-info {
  padding: mixins.toRem(20) mixins.toRem(16);

  border-radius: 12px 12px 0 0;
  color: var(--white);
  background-color: var(--blue);

  // .transaction-detail-info__title

  &__title {
    margin-bottom: mixins.toRem(24);
    color: var(--white);
  }

  // .transaction-detail-info__text

  &__text {
    margin-bottom: mixins.toRem(20);
    font-size: mixins.toRem(17);
    line-height: 120%;
    text-align: center;
  }

  // .transaction-detail-info__value

  &__value {
    color: var(--orange);
  }

  // .transaction-detail-info__code

  &__code {
    padding: mixins.toRem(15) mixins.toRem(30);
    margin-bottom: mixins.toRem(20);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: mixins.toRem(13);
    border: 1px dashed var(--gray2);
    border-radius: 8px;

    @media (max-width: mixins.toEem(380)) {
      padding: mixins.toRem(15) mixins.toRem(10);
    }
  }

  // .transaction-detail-info__code-value

  &__code-value {
    font-weight: 700;
    font-size: mixins.toRem(16);

    @media (max-width: mixins.toEem(380)) {
      font-size: mixins.toRem(18);
    }

    @media (max-width: mixins.toEem(340)) {
      font-size: mixins.toRem(16);
    }
  }

  // .transaction-detail-info__code-actions

  &__code-actions {
    display: flex;
    align-items: center;
    gap: mixins.toRem(10);
  }

  // .transaction-detail-info__bottom

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(7);
  }

  // .transaction-detail-info__bottom-item

  &__bottom-item {
    display: flex;
    align-items: center;
    gap: mixins.toRem(4);
    font-size: mixins.toRem(13);
  }

  // .transaction-detail-info__bottom-label

  &__bottom-label {
    font-weight: 700;
  }

  // .transaction-detail-info__bottom-text

  &__bottom-text {
  }
}

.transaction-detail-summary {
  padding: mixins.toRem(18) mixins.toRem(20);
  border: 1px dashed var(--gray2);
  border-radius: 8px;
  text-align: center;

  // .transaction-detail-summary__amount

  &__amount {
    margin-bottom: mixins.toRem(2);
    font-weight: 500;
    font-size: mixins.toRem(20);
    color: #010101;
  }

  // .transaction-detail-summary__text

  &__text {
    font-size: mixins.toRem(14);
    line-height: 160%;
    text-align: center;
    color: var(--gray5);

    span {
      display: block;
    }
  }
}

.transaction-detail-time {
  text-align: center;
  // .transaction-detail-time__text

  &__text {
    margin-bottom: mixins.toRem(5);
    font-size: mixins.toRem(14);
    line-height: 160%;
    color: var(--gray5);
  }

  // .transaction-detail-time__timer

  &__timer {
    font-weight: 500;
    font-size: mixins.toRem(24);
    line-height: 100%;
    color: var(--black);
  }
}

.guarantee {
  margin-top: mixins.toRem(10);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: mixins.toRem(5);
  // .guarantee__icon

  &__icon {
  }

  // .guarantee__text

  &__text {
    font-size: mixins.toRem(12);
    line-height: 100%;
    text-align: center;
    color: #010101;
  }
}
