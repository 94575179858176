.main {}

.deal-done {

  // .deal-done__done

  &__done {
    margin-bottom: mixins.toRem(30);
  }

  // .deal-done__rating

  &__rating {
    margin-bottom: mixins.toRem(43);
  }

  // .deal-done__rating-title

  &__rating-title {
    margin-bottom: mixins.toRem(14);
    line-height: 120%;
    text-align: center;
    color: #010101;
  }

  // .deal-done__review

  &__review {
    margin-bottom: mixins.toRem(10);
  }

  // .deal-done__feedback

  &__feedback {}
}



.rating {

  // .rating__items

  &__items {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    gap: mixins.toRem(2);

  }

  // .rating__item

  &__item {
    opacity: 0;
    width: mixins.toRem(0);
    cursor: pointer;

    &:checked~.rating__label {
      background: url('../../../public/img/svg/star-active.svg') 0 0 / mixins.toRem(42) no-repeat;
    }

    &:focus+.rating__label {
      background: url('../../../public/img/svg/star-active.svg') 0 0 / mixins.toRem(42) no-repeat;
    }
  }

  // .rating__label

  &__label {
    cursor: pointer;
    width: mixins.toRem(42);
    height: mixins.toRem(42);
    background: url('../../../public/img/svg/star.svg') 0 0 / mixins.toRem(42) no-repeat;
  }
}

.review {

  // .review__items

  &__items {
    display: flex;
    justify-content: start;
    gap: mixins.toRem(10);
    flex-wrap: wrap;

  }

  // .review__item

  &__item {
    display: none;
    opacity: 0;
    width: mixins.toRem(0);
    cursor: pointer;

    &:checked+.review__label {
      background-color: var(--blue);
      color: var(--white);

      &::before {
        display: block;
      }
    }


  }

  // .review__label

  &__label {
    padding: mixins.toRem(6) mixins.toRem(13);
    font-size: mixins.toRem(12);
    line-height: 120%;
    display: inline-flex;
    cursor: pointer;
    gap: mixins.toRem(8);
    align-items: center;
    justify-content: center;
    color: #010101;
    position: relative;
    border-radius: 50px;
    background-color: var(--gray1);
    text-wrap: nowrap;

    &::before {
      content: '';
      // position: absolute;
      width: mixins.toRem(12);
      height: mixins.toRem(9);
      background: url('../../../public/img/svg/check.svg') center no-repeat;
      display: none;
    }
  }
}

.feedback {
  padding: mixins.toRem(14);
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  width: 100%;
  height: mixins.toRem(112);
  background: #ecebf1;

  // .feedback__textarea
  &__textarea {
    flex: 1 1 auto;
    resize: none;
    font-size: mixins.toRem(12);
    line-height: 130%;
    color: #010101;
    outline: none;
  }

  // .feedback__count

  &__count {
    align-self: end;
    font-size: mixins.toRem(11);
    line-height: 130%;
    color: #999;
  }
}



// .rating__item:checked~.rating__label {
//   color: var(--orange);
// }

// .rating__item:focus~.rating__label {
//   color: var(--orange);
// }
