.main {}

.transaction {

  // .transaction__title

  &__title {
    margin-bottom: mixins.toRem(19);
  }

  // .transaction__time

  &__time {

    margin-bottom: mixins.toRem(18);
    text-align: center;
  }

  // .transaction__time-text

  &__time-text {
    margin-bottom: mixins.toRem(7);
    font-size: mixins.toRem(14);
    color: var(--gray5);
  }

  // .transaction__time-timer

  &__time-timer {
    font-weight: 500;
    font-size: mixins.toRem(24);
    color: var(--black);
  }

  // .transaction__type

  &__type {
    margin-top: mixins.toRem(30);
    margin-bottom: mixins.toRem(10);
    font-size: mixins.toRem(14);
    color: #2c2c2e;
  }

  // .transaction__type-value

  &__type-value {
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none;
    color: var(--dark-blue);
  }

  // .transaction__details

  &__details {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(15)
    }

    display: flex;
    flex-direction: column;
    gap: mixins.toRem(14);
  }

  // .transaction__details-item

  &__details-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: mixins.toRem(4);
    font-size: mixins.toRem(14);

    @media (max-width: mixins.toEem(340)) {
      font-size: mixins.toRem(13);
    }
  }

  // .transaction__details-label

  &__details-label {}

  // .transaction__details-value

  &__details-value {
    font-weight: 700;
    &--order {
      color: var(--dark-blue);
    }
  }

  // .transaction__details-copy

  &__details-copy {
    margin-left: mixins.toRem(6);
  }

  // .transaction__details-copy-icon

  &__details-copy-icon {
    width: mixins.toRem(20);
    height: mixins.toRem(20);
    fill: var(--blue);

    &--white {
      fill: var(--white);
    }
  }

  // .transaction__footer

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: mixins.toRem(16);
  }

  // .transaction__buttons

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: mixins.toRem(10);
  }

  // .transaction__chat

  &__chat {
    display: flex;
    align-items: center;
    gap: mixins.toRem(6);

  }

  // .transaction__chat-icon

  &__chat-icon {
    width: mixins.toRem(16);
    height: mixins.toRem(16);
  }

  // .transaction__chat-link

  &__chat-link {
    font-size: mixins.toRem(14);

    text-decoration: underline;
    text-decoration-skip-ink: none;
    text-align: center;
    color: var(--blue);

    @media (any-hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
  }
}
