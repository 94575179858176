.entering-details-cash {

  // .entering-details-cash__form

  &__form {}

  // .entering-details-cash__form-items

  &__form-items {
    margin-bottom: mixins.toRem(20);
  }

  // .entering-details-cash__address-title

  &__address-title {
    margin-bottom: mixins.toRem(12);
    font-weight: 700;
    font-size: mixins.toRem(14);
    color: var(--black);
  }
}