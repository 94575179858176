.main {
}

.trader-main {
  margin-bottom: 12px;

  // .trader-main__title

  &__title {
    margin-bottom: mixins.toRem(16);
  }

  // .trader-main__wallet

  &__wallet {
    margin-bottom: mixins.toRem(10);
  }

  // .trader-main__actions

  &__actions {
    margin-bottom: mixins.toRem(55);
    display: flex;
    gap: mixins.toRem(10);
  }

  // .trader-main__table

  &__table {
  }
}

.wallet-trader {
  padding: mixins.toRem(20);
  background-color: var(--blue);
  border-radius: 12px;

  // .wallet-trader__items

  &__items {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(14);
    }

    color: var(--white);
  }

  // .wallet-trader__item

  &__item {
    &:not(:last-child) {
      margin-bottom: mixins.toRem(14);
    }
  }

  // .wallet-trader__label

  &__label {
    margin-bottom: mixins.toRem(4);
    display: block;
    font-size: mixins.toRem(12);
  }

  // .wallet-trader__balance

  &__balance {
    font-weight: 500;
    font-size: mixins.toRem(36);
  }

  // .wallet-trader__balance-currency

  &__balance-currency {
    font-weight: 400;
    font-size: mixins.toRem(24);
  }

  // .wallet-trader__guarantee

  &__guarantee {
    font-weight: 500;
    font-size: mixins.toRem(24);
  }

  // .wallet-trader__guarantee-currency

  &__guarantee-currency {
    font-size: mixins.toRem(16);
  }

  // .wallet-trader__convert

  &__convert {
  }
}
